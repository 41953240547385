import React from "react"
import { StaticImage } from "gatsby-plugin-image"


export default function Sidebar() {
  return (

            <div className="bg-slate-300 rounded-lg text-center py-4 px-4 sm:py-8 sm:px-6 lg:px-8">


                    <div className="flex flex-col flex-grow  text-center py-8">
                        <h2 className="text-2xl font-extrabold text-slate sm:text-3xl">
                        <span className="block">La thérapie de couple vous aidera à
surmonter vos difficultés</span>
                        </h2>
                        <p className="mt-4 text-lg leading-6 text-slate-500">
                        Thérapie de la relation à la ville, au travail et dans le couple, sexothérapie / Coaching adultes HPi (sociophobie, insomnies, troubles anxieux, burn-out etc.) / Workshops, formation, mentoring, supervision.
                        </p>
                        <p className="mt-4 leading-6 text-green-200">
                          <a href="/contact/">
                            <StaticImage
                              className="bg-white rounded-md"
                              src="../../images/sidebar/therapies-couple-sidebar.jpg" 
                              alt=""
                            />
                          </a>
                        </p>
                        <p className="mt-4 text-right text-lg leading-6 text-[#4DB2E0]">
                          <a href="/contact/" className="underline">
                            En savoir plus
                          </a>
                        </p>
                    </div>

                    
            </div>



        )
    }